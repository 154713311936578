import React, { useState, useEffect } from "react";

export default function CodeValidation() {
  const [savedPassword, setSavedPassword] = useState("");
  const [savedCode, setSavedCode] = useState("");
  const [warningShown, setWarningShown] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [redeemError, setRedeemError] = useState("");
  const [result, setResult] = useState(null);

  useEffect(() => {
    const urlSegments = window.location.pathname.split('/').filter(Boolean);
    const businessNameFromUrl = urlSegments[urlSegments.length - 1] || 'Business Name';
    setBusinessName(businessNameFromUrl);
    document.title = `${businessNameFromUrl.charAt(0).toUpperCase() + businessNameFromUrl.slice(1)} - Nusmark Code Validation`;

    document.getElementById('business-name').textContent = businessNameFromUrl.charAt(0).toUpperCase() + businessNameFromUrl.slice(1);
  }, []);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const password = event.target.password.value;
    document.getElementById('login-container').classList.add('hidden');

    setIsLoading(true);
    setLoginError("");

    try {
      const response = await fetch(`https://discount-codes-service-1-sguhogpuha-uc.a.run.app/verify_login?business_name=${encodeURIComponent(businessName)}&password=${encodeURIComponent(password)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      if (jsonResponse.valid) {
        setSavedPassword(password);
        setIsLoading(false);
        document.getElementById('form-container').classList.remove('hidden');
      } else {
        setLoginError('Invalid login. Please try again.');
        setIsLoading(false);
        document.getElementById('login-container').classList.remove('hidden');
      }
    } catch (error) {
      setLoginError('Error during login. Please try again.');
      setIsLoading(false);
      document.getElementById('login-container').classList.remove('hidden');
    }
  };

  const handleRedeemSubmit = async (event) => {
    event.preventDefault();
    const discountCode = document.getElementById('discount_code').value.trim(); 
    const transactionAmount = document.getElementById('post_vat_transaction_amount').value;

    document.getElementById('form-container').classList.add('hidden');
    setIsLoading(true);
    setRedeemError("");
    setResult(null);

    try {
      const response = await fetch(`https://discount-codes-service-1-sguhogpuha-uc.a.run.app/check_redeem_code?code=${encodeURIComponent(discountCode)}&place_name=${encodeURIComponent(businessName)}&place_code=${encodeURIComponent(savedPassword)}&transaction_amount=${encodeURIComponent(transactionAmount)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.error || `HTTP error! Status: ${response.status}`);
      }

      const jsonResponse = await response.json();

      setIsLoading(false);

      if (jsonResponse.valid) {
        setSavedCode(discountCode);
        setResult({
          status: jsonResponse.status === "success" ? 'Valid Code!' : 'Invalid Code',
          valid: jsonResponse.valid,
          reply: jsonResponse.reply || '',
          amount: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(jsonResponse.discounted_transaction_amount),
          firstTimeCustomer: jsonResponse.first_time_customer,
          discountNote: jsonResponse.discount_message,
        });
      } else {
        setRedeemError(jsonResponse.reply || 'Invalid code. Please try again.');
        document.getElementById('form-container').classList.remove('hidden');
      }
    } catch (error) {
      setRedeemError(error.message);
      setIsLoading(false);
      document.getElementById('form-container').classList.remove('hidden');
    }
  };

          
            // document.getElementById('pre-vat-input').addEventListener('submit', async function (event) {
            //     event.preventDefault();
            //     const preVatAmount = document.getElementById('pre_vat_transaction_amount').value;

            //     document.getElementById('result-container').classList.add('hidden');
            //     document.getElementById('loading-container').classList.remove('hidden');

            //     try {
            //         const response = await fetch(`${apiUrl}/update_transaction_amount_vat?code=${encodeURIComponent(savedCode)}&place_name=${encodeURIComponent(businessName)}&place_code=${encodeURIComponent(savedPassword)}&transaction_amount=${encodeURIComponent(preVatAmount)}`, {
            //             method: 'POST',
            //             headers: {
            //                 'Content-Type': 'application/json',
            //             }
            //         });

            //         const jsonResponse = await response.json();

            //         document.getElementById('loading-container').classList.add('hidden');

            //         if (jsonResponse.updated) {
            //             document.getElementById('result-container').classList.add('hidden');
            //             document.getElementById('form-container').classList.remove('hidden');
            //             document.getElementById('discount_code').value = '';
            //             document.getElementById('post_vat_transaction_amount').value = '';
            //             document.getElementById('redeem-error-message').classList.add('hidden');
            //             document.getElementById('undo-error-message').classList.add('hidden');
            //         } else {
            //             document.getElementById('undo-error-message').classList.remove('text-orange-500');
            //             document.getElementById('undo-error-message').classList.add('text-red-500');
            //             document.getElementById('undo-error-message').textContent = jsonResponse.error || 'Failed to update the transaction amount.';
            //             document.getElementById('undo-error-message').classList.remove('hidden');
            //             document.getElementById('result-container').classList.remove('hidden');
            //         }
            //     } catch (error) {
            //         document.getElementById('undo-error-message').classList.remove('text-orange-500');
            //         document.getElementById('undo-error-message').classList.add('text-red-500');
            //         document.getElementById('undo-error-message').textContent = 'Error updating transaction amount: ' + error.message;
            //         document.getElementById('undo-error-message').classList.remove('hidden');
            //         document.getElementById('loading-container').classList.add('hidden');
            //     }
            // });

  const handleReset = () => {
    setResult(null);
    setRedeemError("");
    document.getElementById('form-container').classList.remove('hidden');
    document.getElementById('post_vat_transaction_amount').value = '';
    document.getElementById('discount_code').value = '';

  };

  const handleUndo = async () => {
    setIsLoading(true);
    setRedeemError("");
    setResult(null);

    try {
      const response = await fetch(`https://discount-codes-service-1-sguhogpuha-uc.a.run.app/undo_redeem_code?code=${encodeURIComponent(savedCode)}&place_name=${encodeURIComponent(businessName)}&place_code=${encodeURIComponent(savedPassword)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const jsonResponse = await response.json();

      setIsLoading(false);

      if (jsonResponse.undone) {
        setResult(null);
        document.getElementById('form-container').classList.remove('hidden');

      } else {
        setRedeemError(jsonResponse.error || 'Failed to undo the code.');
        document.getElementById('form-container').classList.remove('hidden');

      }
    } catch (error) {
      setRedeemError('Error undoing the code: ' + error.message);
      setIsLoading(false);
      document.getElementById('form-container').classList.remove('hidden');

    }
  };

  return (
    <main className="bg-white font-biryani flex items-center justify-center">
      <section className="bg-transparent py-10">
        <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 id="business-name" className="text-4xl font-bold text-center text-black mb-6"></h1>

          {/* Login Container */}
          <div id="login-container" className="bg-black p-6 rounded-lg shadow-lg">
            <form id="login-form" onSubmit={handleLoginSubmit}>
              <label htmlFor="password" className="block mb-2 text-lg font-semibold text-white">Enter Business Password:</label>
              <input type="password" id="password" name="password" className="w-full p-2 border border-gray-300 rounded-lg mb-4 text-lg text-black" placeholder="Enter password here" />
              <button type="submit" className="w-full p-3 bg-blue-500 text-white font-semibold rounded-lg text-lg">Login</button>
              {/* Error message for invalid login */}
              {loginError && <p className="mt-4 text-red-500 text-lg">{loginError}</p>}
            </form>
          </div>

          {/* Discount Code Form Container */}
          <div id="form-container" className="bg-black p-6 rounded-lg shadow-lg hidden">
            <form id="redeem-form" onSubmit={handleRedeemSubmit}>
              <label htmlFor="discount_code" className="block mb-2 text-lg font-semibold text-white">Enter Discount Code:</label>
              <input type="text" id="discount_code" name="discount_code" className="w-full p-2 border border-gray-300 rounded-lg mb-4 text-lg text-black" placeholder="Enter code here" />
              <div className="mb-4">
                <label
                  htmlFor="post_vat_transaction_amount"
                  className="block mb-2 text-lg font-semibold text-white"
                >
                  Enter Transaction Amount:
                </label>
                <div className="flex items-center border border-gray-300 rounded-lg">
                  <span className="px-3 text-lg text-white font-semibold">£</span>
                  <input
                    type="text"
                    id="post_vat_transaction_amount"
                    name="post_vat_transaction_amount"
                    className="w-full p-2 text-lg text-black border-none focus:ring-0 focus:outline-none"
                    placeholder="Enter amount here"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full p-3 bg-blue-500 text-white font-semibold rounded-lg text-lg"
              >
                Check Code
              </button>

              {/* Error message for invalid code */}
              {redeemError && <p className="mt-4 text-red-500 text-lg">{redeemError}</p>}
            </form>
          </div>

          {/* Loading Spinner Container */}
          {isLoading && (
            <div id="loading-container" className="bg-black p-6 rounded-lg shadow-lg flex flex-col items-center">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-white border-t-blue-500 border-r-blue-500"></div>
              <p className="text-white mt-4 text-lg">Processing...</p>
            </div>
          )}

          {/* Result Container */}
          {result && (
            <>
              {result.firstTimeCustomer && (
                <p className="text-center text-2xl font-medium">You got a new customer 🥳</p>
              )}
              <div id="result-container" className="bg-black p-6 rounded-lg shadow-lg text-white">
                <h2 className="text-lg font-semibold">{result.status}</h2>
                <p className="mt-4 text-lg">{result.reply}</p>
                <p className="mt-4 text-lg">{"Due after discount: " + result.amount}</p>
                {result.discountNote && (
                  <p className="mt-4 text-lg font-bold">{"Discount note: " + result.discountNote}</p>
                )}
                <button
                  id="reset-button"
                  onClick={handleReset}
                  className="w-full mt-6 p-3 bg-blue-500 text-white font-semibold rounded-lg text-lg"
                >
                  Enter another code
                </button>
                <button
                  id="undo-button"
                  onClick={handleUndo}
                  className="w-full mt-6 p-3 bg-blue-500 text-white font-semibold rounded-lg text-lg"
                >
                  Cancel
                </button>
              </div>
            </>
          )}

          {/* <!-- <form id="pre-vat-input">
              <label for="pre_vat_transaction_amount" class="block mt-6 mb-2 text-lg font-semibold text-white">Enter Pre-VAT Amount:</label>
              <input type="text" id="pre_vat_transaction_amount" name="pre_vat_transaction_amount" class="w-full p-2 border border-gray-300 rounded-lg mb-4 text-lg text-black" placeholder="Enter amount here">
              <button type="submit" class="w-full p-3 bg-blue-500 text-white font-semibold rounded-lg text-lg">Save Pre-VAT Amount</button>
          </form> -->
          <!-- Error message --> */}
        </div>
      </section>
    </main>
  );
}
