import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import Home from './pages/Home';
import { useMediaQuery } from "react-responsive";
import Footer from './components/Footer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CodeValidation from './pages/CodeValidation';
import NotFound from './pages/NotFound';
import LinkCalendar from './pages/LinkCalendar';

export default function App() {
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            {isMobile ? <MobileNavbar /> : <Navbar />}
            <Home />
            <Footer />
          </>
        } />
         <Route path="/privacy-policy" element={
          <>
            {isMobile ? <MobileNavbar /> : <Navbar />}
            <PrivacyPolicy />
            <Footer />
          </>
        } />
        <Route path="/code-validation/*" element={
          <>
            {isMobile ? <MobileNavbar /> : <Navbar />}
            <CodeValidation />
            <Footer />
          </>
        } />
        <Route path="/calendar-linked/*" element={<LinkCalendar />} />
        <Route path="/*" element={
          <>
            {isMobile ? <MobileNavbar /> : <Navbar />}
            <NotFound />
            <Footer />
          </>
        } />
      </Routes>
    </Router>
  );
}