import React, { useEffect } from "react";

export default function NotFound() {

	useEffect(() => {
		document.title = 'Not Found - Nusmark';
	  }, []);

	return(
		<main classNameName='bg-white font-biryani flex items-center justify-center h-100'>
			<div className="text-center bg-white h-screen items-center p-8 rounded-lg shadow-md">
				<h1 className="text-9xl font-bold text-black">404</h1>
				<h2 className="text-3xl font-semibold text-gray-800 mt-4">Page Not Found</h2>
				<p className="text-lg text-gray-600 mt-2">Sorry, the page you're looking for doesn't exist.</p>
				<a className="inline-flex font-semibold justify-center items-center rounded-lg border bg-white pb-[calc(theme(spacing.2)-2px)] pt-[calc(theme(spacing.2)+1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 hover:bg-gray-100 active:bg-gray-200 active:text-gray-700/80" variant="outline" color="gray" href="/">Go Back Home</a>
    	</div>
		</main>
	)
}