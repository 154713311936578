import React, { useState, useEffect } from 'react';
import WhatsAppButton from "../media/whatsapp_button.png";
import BCGLogo from "../media/clogo2.png";
import PWPLogo from "../media/clogo3.png";
import WhatsAppBotImage from "../media/chat2-1.png";
import Dashboard from "../media/dashboard.png";
import Phone1 from "../media/phone1.jpeg";
import Phone2 from "../media/phone2.jpeg";
import Phone3 from "../media/phone3.jpeg";
import Dhruv from "../media/dhruv.png";
import Jakub from "../media/jakub.jpeg";
import Oxford from "../media/oxford.png";
import Amazon from "../media/amazon.png";
import Soton from "../media/soton.png";
import GE from "../media/ge.png";
import Wyman from "../media/clogo1.png"

export default function Home() {
	const [activePhone, setActivePhone] = useState(1);
	const [activeSlide, setActiveSlide] = useState(1);
  const [currentTranslate, setCurrentTranslate] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
    document.title = 'Nusmark';
  }, []);

  const handleSlideChange = (slideNumber) => {
    setActiveSlide(slideNumber);
  };

  const touchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const touchMove = (e) => {
    if (isDragging) {
      const diffX = e.touches[0].clientX - startX;
      setCurrentTranslate(diffX);
    }
  };

  const touchEnd = () => {
    setIsDragging(false);
    const movedBy = currentTranslate;

    if (movedBy < -50 && currentIndex < 2) {
      setCurrentIndex(currentIndex + 1);
      handleSlideChange(currentIndex + 2);
    } else if (movedBy > 50 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      handleSlideChange(currentIndex);
    }

    setCurrentTranslate(0);
  };

	return (
		<main className='bg-white font-biryani'>

			{/* Hero Section */}
			<section>
				<div className="overflow-hidden py-4 sm:py-32 lg:pb-32 xl:pb-36">
					<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
						<div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
							<div className="relative mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
									<h1 className="text-5xl font-medium tracking-tight text-gray-900 leading-tight">The AI-Powered PA for your life, not just work.</h1>
									<p className="mt-6 text-lg text-gray-600">Nusmark aligns calendars, emails, and messages to accurately identify your Tasks, Events, and Reminders (TERs) at and around work.</p>
									<p className="mt-5 text-lg text-gray-600">Then it helps you make the most of your newfound free time to organise discounted bookings and purchases at your favourite restaurants, shops, and wellness centres</p>

									<div className="relative z-20 mt-8 flex flex-wrap items-center gap-4 lg:flex-nowrap">
										{/* Try on WhatsApp Button */}
										<a 
											aria-label="Try on WhatsApp" 
											className="flex items-center rounded-lg bg-gray-800 text-white px-4 py-2 transition transform hover:scale-105 w-[140px] h-[40px] flex-shrink-0" 
											href="https://wa.me/442089418866?text=Press%20the%20send%20button%20(to%20the%20right)%20to%20get%20started%20--%3E"
											target="_blank" 
											rel="noreferrer"
										>
											<img 
												src={WhatsAppButton} 
												alt="WhatsApp button" 
												className="w-full h-10 object-cover"
											/>
										</a>

										{/* Nusmark for Companies Button */}
										<a 
											className="text-md font-semibold rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-700 transition-colors hover:border-gray-400 hover:bg-gray-100 active:bg-gray-200 active:text-gray-700/80 whitespace-nowrap flex-shrink-0" 
											href="#corporate_benefits"
										>
											Nusmark for Companies
										</a>

										{/* Become a Discount Partner Button */}
										<a 
											className="text-md font-semibold rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-700 transition-colors hover:border-gray-400 hover:bg-gray-100 active:bg-gray-200 active:text-gray-700/80 whitespace-nowrap flex-shrink-0 mt-2 sm:mt-0" 
											href="#discount_provider"
										>
											Become a Discount Partner
										</a>
									</div>

							</div>
							<div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6 z-0">
										<div className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0">
											<svg viewBox="0 0 1026 1026" fill="none" aria-hidden="true" className="absolute inset-0 h-full w-full animate-spin-slow">
													<path d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z" stroke="#D4D4D4" strokeOpacity="0.7"></path>
													<path d="M513 1025C230.23 1025 1 795.77 1 513" stroke="url(#:S1:-gradient-1)" strokeLinecap="round"></path>
													<defs>
															<linearGradient id=":S1:-gradient-1" x1="1" y1="513" x2="1" y2="1025" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#06b6d4"></stop>
																	<stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
															</linearGradient>
													</defs>
											</svg>
											<svg viewBox="0 0 1026 1026" fill="none" aria-hidden="true" className="absolute inset-0 h-full w-full animate-spin-reverse-slower">
													<path d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z" stroke="#D4D4D4" strokeOpacity="0.7"></path>
													<path d="M913 513c0 220.914-179.086 400-400 400" stroke="url(#:S1:-gradient-2)" strokeLinecap="round"></path>
													<defs>
															<linearGradient id=":S1:-gradient-2" x1="913" y1="513" x2="913" y2="913" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#06b6d4"></stop><stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
															</linearGradient>
													</defs>
											</svg>
										</div>
										{/* Weird gradient thing */}
										<div className="mobile-gradient desktop-gradient -mx-4 h-[448px] bg-center bg-cover px-9 sm:bg-center sm:bg-cover sm:relative sm:top-0 sm:mx-0 lg:absolute lg:-inset-x-10 lg:-bottom-20 lg:-top-10 lg:h-auto lg:px-0 lg:pt-10 xl:-bottom-32">
											<div className="relative aspect-[366/729] mx-auto max-w-[366px]">
													<div className="absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl bg-white"></div>
													<div className="absolute left-[calc(23/366*100%)] top-[calc(23/729*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] grid-cols-1 overflow-hidden pt-[calc(23/318*100%)]">
														<img src={WhatsAppBotImage} alt="Picture of a user using the Nusmark chat bot" />
													</div>
													<svg viewBox="0 0 366 729" aria-hidden="true" className="pointer-events-none absolute inset-0 h-full w-full fill-gray-100">
															<path fill="#F2F2F2" fillRule="evenodd" clipRule="evenodd" d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"></path>
															<rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4"></rect>
													</svg>
													<svg width="366" height="729" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden">
															<g mask="url(#mask)">
																<g filter="url(#a)">
																	<path
																		d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
																		fill="url(#b)" />
																	<path
																		d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
																		fill="url(#c)" />
																</g>
																<g filter="url(#d)">
																	<path
																		d="M5 133.772v-21.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v24.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 133.772Z"
																		fill="url(#e)" />
																	<path
																		d="M5 133.772v-21.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v24.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 133.772Z"
																		fill="url(#f)" fillOpacity=".1" />
																</g>
																<g filter="url(#g)">
																	<path
																		d="M5 213.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 213.772Z"
																		fill="url(#h)" />
																	<path
																		d="M5 213.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 213.772Z"
																		fill="url(#i)" fillOpacity=".1" />
																</g>
																<g filter="url(#j)">
																	<path
																		d="M5 283.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 283.772Z"
																		fill="url(#k)" />
																	<path
																		d="M5 283.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 283.772Z"
																		fill="url(#l)" fillOpacity=".1" />
																</g>
																<g filter="url(#m)">
																	<path
																		d="M362.004 266.772v-78.15a5.12 5.12 0 0 1 1.5-3.622c.844-.073 2.496.257 2.496 2.157v81.562c-.406 2.023-2.605 2.023-2.605 2.023a6.359 6.359 0 0 1-1.391-3.97Z"
																		fill="url(#n)" />
																	<path
																		d="M362.004 266.772v-78.15a5.12 5.12 0 0 1 1.5-3.622c.844-.073 2.496.257 2.496 2.157v81.562c-.406 2.023-2.605 2.023-2.605 2.023a6.359 6.359 0 0 1-1.391-3.97Z"
																		fill="url(#o)" fillOpacity=".1" />
																</g>
																<path
																	d="M305 14.5H59c-24.577 0-44.5 19.923-44.5 44.5v615c0 23.472 19.028 42.5 42.5 42.5h250c23.472 0 42.5-19.028 42.5-42.5V59c0-24.577-19.923-44.5-44.5-44.5Z"
																	stroke="url(#p)" strokeOpacity=".5" />
																<g filter="url(#q)" shapeRendering="crispEdges">
																	<path
																		d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
																		fill="url(#r)" fillOpacity=".3" />
																	<path
																		d="M305 15.5H59c-24.024 0-43.5 19.476-43.5 43.5v615c0 23.472 19.028 42.5 42.5 42.5h248c23.472 0 42.5-19.028 42.5-42.5V59c0-24.024-19.476-43.5-43.5-43.5Z"
																		stroke="#000" strokeOpacity=".07" />
																</g>
																<g filter="url(#s)">
																	<rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4" />
																</g>
															</g>
															<defs>
																<mask id="mask">
																	<rect width="366" height="729" fill="#fff" />
																	<path fillRule="evenodd" clipRule="evenodd"
																		d="M89.728 24a4.213 4.213 0 0 1 4.213 4.212v2.527c0 10.235 8.3 18.532 18.539 18.532h139.04c10.239 0 18.539-8.297 18.539-18.532v-2.527A4.212 4.212 0 0 1 274.272 24h32.864C325.286 24 340 38.71 340 56.853v618.295c0 18.144-14.714 32.853-32.864 32.853H56.864c-18.15 0-32.864-14.709-32.864-32.853V56.853C24 38.709 38.714 24 56.864 24h32.864Z"
																		fill="#000" />
																</mask>
																<linearGradient id="e" x1="1.004" y1="123.367" x2="5" y2="123.367" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</linearGradient>
																<linearGradient id="f" x1="3.002" y1="108.991" x2="3.002" y2="116.75" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="h" x1="1.004" y1="190.867" x2="5" y2="190.867" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</linearGradient>
																<linearGradient id="i" x1="3.002" y1="163.991" x2="3.002" y2="178.497" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="k" x1="1.004" y1="260.867" x2="5" y2="260.867" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</linearGradient>
																<linearGradient id="l" x1="3.002" y1="233.991" x2="3.002" y2="248.497" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="n" x1="362.004" y1="226.25" x2="366" y2="226.25" gradientUnits="userSpaceOnUse">
																	<stop offset=".124" stopColor="#E6E6E6" />
																	<stop offset="1" stopColor="#D4D4D4" />
																</linearGradient>
																<linearGradient id="o" x1="364.002" y1="184.991" x2="364.002" y2="208.134" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="p" x1="182" y1="15" x2="182" y2="716" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#fff" />
																	<stop offset=".381" stopColor="#fff" stopOpacity="0" />
																</linearGradient>
																<filter id="a" x="-1" y="-1" width="367" height="730.314" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="-2" />
																	<feGaussianBlur stdDeviation="1.5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
																	<feBlend in2="shape" result="effect1_innerShadow_104_2007" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-2" />
																	<feGaussianBlur stdDeviation="2" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.17 0" />
																	<feBlend in2="effect1_innerShadow_104_2007" result="effect2_innerShadow_104_2007" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="2" />
																	<feGaussianBlur stdDeviation=".5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
																	<feBlend in2="effect2_innerShadow_104_2007" result="effect3_innerShadow_104_2007" />
																</filter>
																<filter id="d" x="1.004" y="108.991" width="4.996" height="28.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="g" x="1.004" y="163.991" width="4.996" height="53.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="j" x="1.004" y="233.991" width="4.996" height="53.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="m" x="361.004" y="184.991" width="4.996" height="85.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="q" x="15" y="15" width="334" height="703" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feGaussianBlur stdDeviation="2.5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="s" x="154" y="29" width="56" height="6" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feGaussianBlur stdDeviation=".5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<radialGradient id="b" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																	gradientTransform="matrix(0 727 -642 0 184 1)">
																	<stop stopColor="#FAFAFA" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</radialGradient>
																<radialGradient id="c" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																	gradientTransform="matrix(0 319 -295.5 0 183.5 1)">
																	<stop stopColor="#fff" />
																	<stop offset=".533" stopColor="#fff" stopOpacity="0" />
																</radialGradient>
																<radialGradient id="r" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																	gradientTransform="matrix(0 689 -326.783 0 182 27)">
																	<stop offset=".319" stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</radialGradient>
															</defs>
														</svg>
											</div>
										</div>
										
							</div>
								<div className="relative -mt-4 lg:col-span-7 lg:mt-0 xl:col-span-6">
										<p className="text-center text-sm font-semibold text-gray-900 lg:text-left">Loved by employees at:</p>
										<ul role="list" className="mx-auto mt-8 flex max-w-xl flex-wrap justify-center gap-x-10 gap-y-8 lg:mx-0 lg:justify-start">
												<li className="flex">
														<img alt="BCG" loading="lazy" decoding="async" data-nimg="1" className="h-7 text-transparent" src={BCGLogo}/>
												</li>
												<li className="flex">
														<img alt="PWP" loading="lazy" decoding="async" data-nimg="1" className="h-9 text-transparent" src={PWPLogo}/>
												</li>
											
												<li className="flex">
														<img alt="Oliver Wyman" loading="lazy" decoding="async" data-nimg="1" className="h-7 text-transparent" src={Wyman}/>
												</li> 
										
												<li>
														<p className="text-xs text-gray-500">Endorsements from employees, not on behalf of company... yet!</p>
												</li>
										</ul>
								</div>
						</div>
					</div>
				</div>
			</section>

			{/*Corporate Benefits Section*/}
			<section>
					<div className="overflow-hidden bg-black py-24 sm:py-32">
							<div className="mx-auto max-w-7xl px-6 lg:px-8">
								<div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
									<div className="ml-auto lg:pr-8 lg:pt-4" id="corporate_benefits">
										<div className="lg:max-w-lg">
											<h2 className="text-base font-semibold leading-7 text-gray-300">Corporate Benefits</h2>
											<div className="flex justify-between">
													<p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Set by you</p>
											</div>
											<p className="mt-6 text-lg leading-8 text-gray-400">Stop the expenses free-for-all.</p>
											<p className="mt-3 text-lg leading-8 text-gray-400">Let HR control budgets, choose exclusive partners for bigger discounts, and get spending insights while offering employees perks they actually want.</p>
											<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
												<div className="relative pl-9">
													<dt className="inline font-semibold text-white">
														<svg className="absolute left-1 top-1 h-5 w-5 text-gray-300 size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
															<path fillRule="evenodd" d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
															<path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
														</svg>
														Employers...
													</dt>
													<dd className="inline pl-2 text-gray-400">can let employees choose how to boost wellbeing. Set per-employee and/or per-perk budgets. Track to ensure usage value, and switch instantly if needed.</dd>
												</div>
												<div className="relative pl-9">
													<dt className="inline font-semibold text-white">
														<svg className="absolute left-1 top-1 h-5 w-5 text-gray-300 size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
															<path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clipRule="evenodd" />
															<path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
														</svg>                                  
														Employees...
													</dt>
													<dd className="inline pl-2 text-gray-400">can effortlessly organise work and life with an AI PA on WhatsApp, Slack, Teams, and more. Manage perks and have bookings scheduled for you.</dd>
												</div>
												<div className="relative pl-9">
													<dt className="inline font-semibold text-white">
														<svg className="absolute left-1 top-1 h-5 w-5 text-gray-300 size-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
															<path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z" />
															<path fillRule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z" clipRule="evenodd" />
														</svg>                                                                   
														Merchants...
													</dt>
													<dd className="inline pl-2 text-gray-400">can tap into increasing corporate benefit budgets. Gain visibility to target customers in local offices and beyond, plus get AI integration with no additional cost.</dd>
												</div>
											</dl>
										</div>
									</div>
									<div className="flex items-start justify-end order-first">
											<img src={Dashboard} alt="Product screenshot" className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0" width="2432" height="1442" />
									</div>
								</div>
							</div>
					</div>
			</section>
			
			{/*Discount Partners Section*/}
			<section aria-label="Discount partners" className="bg-gray-900 py-20 sm:py-32">
				<div id="discount_provider" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
						<div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
								<h2 className="text-base font-semibold leading-7 text-gray-300">Discount Partners</h2>
								<h2 className="text-3xl font-medium tracking-tight text-white">Reach employees through apps they already use.</h2>
								<p className="mt-2 text-lg text-gray-400">Join Nusmark as a discount partner to tap into corporate spend, attract new customers, and increase visibility. Promote your business to employees seeking exclusive perks, all while boosting local engagement. </p>
								{/* <button 
								onClick={() => window.open("https://merchant.nusmark.com/", "_blank")}
								className='inline-flex font-semibold justify-center items-center rounded-lg border bg-white pb-[calc(theme(spacing.2)-2px)] pt-[calc(theme(spacing.2)+1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 hover:bg-gray-100 active:bg-gray-200 active:text-gray-700/80'
								>
									Apply to be a Discount Partner
								</button> */}
						</div>
				</div>

				<div className="mt-16 md:hidden">
						<div className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&amp;::-webkit-scrollbar]:hidden">
								<div 
								onTouchStart={touchStart}
								onTouchMove={touchMove}
								onTouchEnd={touchEnd} 
								className="w-full flex-none snap-center px-4 sm:px-6"
								>
										<div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
												<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
														<svg viewBox="0 0 558 558" width="558" height="558" fill="none" aria-hidden="true">
																<defs>
																		<linearGradient id=":R1b57qfja:" x1="79" y1="16" x2="105" y2="237" gradientUnits="userSpaceOnUse">
																				<stop stopColor="#13B5C8"></stop>
																				<stop offset="1" stopColor="#13B5C8" stopOpacity="0"></stop>
																		</linearGradient>
																</defs>
																<path opacity=".2" d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z" stroke="#13B5C8"></path>
																<path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:R1b57qfja:)" strokeLinecap="round"></path>
														</svg>
												</div>
												<div className="relative aspect-[366/729] relative mx-auto w-full max-w-[366px]">
														<div className="absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl"></div>
														<div className="absolute left-[calc(23/366*100%)] top-[calc(23/729*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden bg-gray-900">
																<img className={`${activeSlide === 1 ? "block" : "hidden"}`} src={Phone2} alt="phone 1" />
																<img className={`${activeSlide === 2 ? "block" : "hidden"}`} src={Phone1} alt="phone 2" />
																<img className={`${activeSlide === 3 ? "block" : "hidden"}`} src={Phone3} alt="phone 3" />
														</div>
														<svg viewBox="0 0 366 729" aria-hidden="true" className="pointer-events-none absolute inset-0 h-full w-full fill-gray-100">
																<path fill="#F2F2F2" fillRule="evenodd" clipRule="evenodd" d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"></path>
																<rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4"></rect>
														</svg>
														<svg width="366" height="729" fill="none" xmlns="http://www.w3.org/2000/svg" className="hidden">
																<g mask="url(#mask)">
																	<g filter="url(#a)">
																		<path
																			d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
																			fill="url(#b)" />
																		<path
																			d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
																			fill="url(#c)" />
																	</g>
																	<g filter="url(#d)">
																		<path
																			d="M5 133.772v-21.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v24.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 133.772Z"
																			fill="url(#e)" />
																		<path
																			d="M5 133.772v-21.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v24.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 133.772Z"
																			fill="url(#f)" fillOpacity=".1" />
																	</g>
																	<g filter="url(#g)">
																		<path
																			d="M5 213.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 213.772Z"
																			fill="url(#h)" />
																		<path
																			d="M5 213.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 213.772Z"
																			fill="url(#i)" fillOpacity=".1" />
																	</g>
																	<g filter="url(#j)">
																		<path
																			d="M5 283.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 283.772Z"
																			fill="url(#k)" />
																		<path
																			d="M5 283.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 283.772Z"
																			fill="url(#l)" fillOpacity=".1" />
																	</g>
																	<g filter="url(#m)">
																		<path
																			d="M362.004 266.772v-78.15a5.12 5.12 0 0 1 1.5-3.622c.844-.073 2.496.257 2.496 2.157v81.562c-.406 2.023-2.605 2.023-2.605 2.023a6.359 6.359 0 0 1-1.391-3.97Z"
																			fill="url(#n)" />
																		<path
																			d="M362.004 266.772v-78.15a5.12 5.12 0 0 1 1.5-3.622c.844-.073 2.496.257 2.496 2.157v81.562c-.406 2.023-2.605 2.023-2.605 2.023a6.359 6.359 0 0 1-1.391-3.97Z"
																			fill="url(#o)" fillOpacity=".1" />
																	</g>
																	<path
																		d="M305 14.5H59c-24.577 0-44.5 19.923-44.5 44.5v615c0 23.472 19.028 42.5 42.5 42.5h250c23.472 0 42.5-19.028 42.5-42.5V59c0-24.577-19.923-44.5-44.5-44.5Z"
																		stroke="url(#p)" strokeOpacity=".5" />
																	<g filter="url(#q)" shapeRendering="crispEdges">
																		<path
																			d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
																			fill="url(#r)" fillOpacity=".3" />
																		<path
																			d="M305 15.5H59c-24.024 0-43.5 19.476-43.5 43.5v615c0 23.472 19.028 42.5 42.5 42.5h248c23.472 0 42.5-19.028 42.5-42.5V59c0-24.024-19.476-43.5-43.5-43.5Z"
																			stroke="#000" strokeOpacity=".07" />
																	</g>
																	<g filter="url(#s)">
																		<rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4" />
																	</g>
																</g>
																<defs>
																	<mask id="mask">
																		<rect width="366" height="729" fill="#fff" />
																		<path fillRule="evenodd" clipRule="evenodd"
																			d="M89.728 24a4.213 4.213 0 0 1 4.213 4.212v2.527c0 10.235 8.3 18.532 18.539 18.532h139.04c10.239 0 18.539-8.297 18.539-18.532v-2.527A4.212 4.212 0 0 1 274.272 24h32.864C325.286 24 340 38.71 340 56.853v618.295c0 18.144-14.714 32.853-32.864 32.853H56.864c-18.15 0-32.864-14.709-32.864-32.853V56.853C24 38.709 38.714 24 56.864 24h32.864Z"
																			fill="#000" />
																	</mask>
																	<linearGradient id="e" x1="1.004" y1="123.367" x2="5" y2="123.367" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#D4D4D4" />
																		<stop offset="1" stopColor="#E6E6E6" />
																	</linearGradient>
																	<linearGradient id="f" x1="3.002" y1="108.991" x2="3.002" y2="116.75" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#171717" />
																		<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																	</linearGradient>
																	<linearGradient id="h" x1="1.004" y1="190.867" x2="5" y2="190.867" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#D4D4D4" />
																		<stop offset="1" stopColor="#E6E6E6" />
																	</linearGradient>
																	<linearGradient id="i" x1="3.002" y1="163.991" x2="3.002" y2="178.497" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#171717" />
																		<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																	</linearGradient>
																	<linearGradient id="k" x1="1.004" y1="260.867" x2="5" y2="260.867" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#D4D4D4" />
																		<stop offset="1" stopColor="#E6E6E6" />
																	</linearGradient>
																	<linearGradient id="l" x1="3.002" y1="233.991" x2="3.002" y2="248.497" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#171717" />
																		<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																	</linearGradient>
																	<linearGradient id="n" x1="362.004" y1="226.25" x2="366" y2="226.25" gradientUnits="userSpaceOnUse">
																		<stop offset=".124" stopColor="#E6E6E6" />
																		<stop offset="1" stopColor="#D4D4D4" />
																	</linearGradient>
																	<linearGradient id="o" x1="364.002" y1="184.991" x2="364.002" y2="208.134" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#171717" />
																		<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																	</linearGradient>
																	<linearGradient id="p" x1="182" y1="15" x2="182" y2="716" gradientUnits="userSpaceOnUse">
																		<stop stopColor="#fff" />
																		<stop offset=".381" stopColor="#fff" stopOpacity="0" />
																	</linearGradient>
																	<filter id="a" x="-1" y="-1" width="367" height="730.314" filterUnits="userSpaceOnUse"
																		colorInterpolationFilters="sRGB">
																		<feFlood floodOpacity="0" result="BackgroundImageFix" />
																		<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dy="-2" />
																		<feGaussianBlur stdDeviation="1.5" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
																		<feBlend in2="shape" result="effect1_innerShadow_104_2007" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="-2" />
																		<feGaussianBlur stdDeviation="2" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.17 0" />
																		<feBlend in2="effect1_innerShadow_104_2007" result="effect2_innerShadow_104_2007" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dy="2" />
																		<feGaussianBlur stdDeviation=".5" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
																		<feBlend in2="effect2_innerShadow_104_2007" result="effect3_innerShadow_104_2007" />
																	</filter>
																	<filter id="d" x="1.004" y="108.991" width="4.996" height="28.751" filterUnits="userSpaceOnUse"
																		colorInterpolationFilters="sRGB">
																		<feFlood floodOpacity="0" result="BackgroundImageFix" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="1" />
																		<feComposite in2="hardAlpha" operator="out" />
																		<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																		<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																		<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="-1" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																		<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																	</filter>
																	<filter id="g" x="1.004" y="163.991" width="4.996" height="53.751" filterUnits="userSpaceOnUse"
																		colorInterpolationFilters="sRGB">
																		<feFlood floodOpacity="0" result="BackgroundImageFix" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="1" />
																		<feComposite in2="hardAlpha" operator="out" />
																		<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																		<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																		<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="-1" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																		<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																	</filter>
																	<filter id="j" x="1.004" y="233.991" width="4.996" height="53.751" filterUnits="userSpaceOnUse"
																		colorInterpolationFilters="sRGB">
																		<feFlood floodOpacity="0" result="BackgroundImageFix" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="1" />
																		<feComposite in2="hardAlpha" operator="out" />
																		<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																		<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																		<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="-1" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																		<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																	</filter>
																	<filter id="m" x="361.004" y="184.991" width="4.996" height="85.751" filterUnits="userSpaceOnUse"
																		colorInterpolationFilters="sRGB">
																		<feFlood floodOpacity="0" result="BackgroundImageFix" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="-1" />
																		<feComposite in2="hardAlpha" operator="out" />
																		<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																		<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																		<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dx="1" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																		<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																	</filter>
																	<filter id="q" x="15" y="15" width="334" height="703" filterUnits="userSpaceOnUse"
																		colorInterpolationFilters="sRGB">
																		<feFlood floodOpacity="0" result="BackgroundImageFix" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dy="1" />
																		<feComposite in2="hardAlpha" operator="out" />
																		<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
																		<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																		<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dy="1" />
																		<feGaussianBlur stdDeviation="2.5" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
																		<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																	</filter>
																	<filter id="s" x="154" y="29" width="56" height="6" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
																		<feFlood floodOpacity="0" result="BackgroundImageFix" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dy="1" />
																		<feComposite in2="hardAlpha" operator="out" />
																		<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
																		<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																		<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																		<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																		<feOffset dy="1" />
																		<feGaussianBlur stdDeviation=".5" />
																		<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																		<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
																		<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																	</filter>
																	<radialGradient id="b" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																		gradientTransform="matrix(0 727 -642 0 184 1)">
																		<stop stopColor="#FAFAFA" />
																		<stop offset="1" stopColor="#E6E6E6" />
																	</radialGradient>
																	<radialGradient id="c" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																		gradientTransform="matrix(0 319 -295.5 0 183.5 1)">
																		<stop stopColor="#fff" />
																		<stop offset=".533" stopColor="#fff" stopOpacity="0" />
																	</radialGradient>
																	<radialGradient id="r" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																		gradientTransform="matrix(0 689 -326.783 0 182 27)">
																		<stop offset=".319" stopColor="#D4D4D4" />
																		<stop offset="1" stopColor="#E6E6E6" />
																	</radialGradient>
																</defs>
															</svg>
												</div>

												<div className={`absolute inset-x-0 bottom-0 bg-gray-800/95 p-6 backdrop-blur sm:p-10 ${activeSlide === 1 ? "block" : "hidden"}`}>
														<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
																<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
																<path fillRule="evenodd" clipRule="evenodd" d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z" fill="#737373"></path>
																<path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z" fill="#A3A3A3"></path>
														</svg>
														<h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">Personalised Recommendations</h3>
														<p className="mt-2 text-sm text-gray-400">Whether it's dining, activities, or services nearby, Nusmark ensures your discounts are easy-access to target customers.</p>
												</div>

												<div className={`absolute inset-x-0 bottom-0 bg-gray-800/95 p-6 backdrop-blur sm:p-10 ${activeSlide === 2 ? "block" : "hidden"}`}>
														<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
																<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
																<path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#A3A3A3"></path>
																<path d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z" fill="#737373"></path>
														</svg>
														<h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">Discount Prioritisation</h3>
														<p className="mt-2 text-sm text-gray-400">Our Marketplace Algorithm ensures popular, higher discount locations receive more traffic... so you get more out of your promotions.</p>
												</div>

												<div className={`absolute inset-x-0 bottom-0 bg-gray-800/95 p-6 backdrop-blur sm:p-10 ${activeSlide === 3 ? "block" : "hidden"}`}>
														<svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
																<defs>
																		<linearGradient id=":Rrdl7qfja:-gradient" x1="14" y1="14.5" x2="7" y2="17" gradientUnits="userSpaceOnUse">
																				<stop stopColor="#737373"></stop>
																				<stop offset="1" stopColor="#D4D4D4" stopOpacity="0"></stop>
																		</linearGradient>
																</defs>
																<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
																<path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z" fill="#A3A3A3"></path>
																<path d="M7 22c0-4.694 3.5-8 8-8" stroke="url(#:Rrdl7qfja:-gradient)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
																<path d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z" fill="#A3A3A3"></path>
														</svg>
														<h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">One-Click Interaction</h3>
														<p className="mt-2 text-sm text-gray-400">Instant code generation on the user end, and we do all the set-up for you on the merchant-side. What’s not to like?</p>
												</div>

										</div>
								</div>
						</div>

						<div className="mt-6 flex justify-center gap-3">
								<div className="mt-6 flex justify-center gap-3">
								<button
								onClick={() => handleSlideChange(1)}
								type="button"
								className={`h-5 w-5 flex items-center justify-center rounded-full bg-transparent font-semibold ${
									activeSlide === 1 ? "text-gray-100" : "text-gray-500"
								}`}
								aria-label="Go to slide 1"
								>
									1
								</button>
								<button
								onClick={() => handleSlideChange(2)}
								type="button"
								className={`h-5 w-5 flex items-center justify-center rounded-full bg-transparent font-semibold ${
									activeSlide === 2 ? "text-gray-100" : "text-gray-500"
								}`}
								aria-label="Go to slide 2"
								>
									2
								</button>
								<button
								onClick={() => handleSlideChange(3)}
								type="button"
								className={`h-5 w-5 flex items-center justify-center rounded-full bg-transparent font-semibold ${
									activeSlide === 3 ? "text-gray-100" : "text-gray-500"
								}`}
								aria-label="Go to slide 3"
								>
									3
								</button>
							</div>
						</div>

				</div>

				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 hidden md:mt-20 md:block">
							<div className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24">
									<div className="relative order-last col-span-6 space-y-6" role="tablist" aria-orientation="vertical">
									
											{/* First Block */}
											<div
											onClick={() => setActivePhone(1)}
											className={`relative rounded-2xl transition-colors hover:bg-gray-800/30 absolute inset-0 border-solid border border-slate-600 cursor-pointer ${
												activePhone === 1 ? "bg-gray-800" : ""
											}`}
											>
												<div className="relative p-8">
													<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
														<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
															fill="#737373"
														></path>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
															fill="#A3A3A3"
														></path>
													</svg>

													<h3 className="mt-6 text-lg font-semibold text-white">
														Personalised Recommendations
													</h3>
													<p className="mt-2 text-sm text-gray-400">
														Whether it's dining, activities, or services nearby, Nusmark ensures your
														discounts are easy-access to target customers.
													</p>
												</div>
											</div>

											{/* Second Block */}
											<div
											onClick={() => setActivePhone(2)}
											className={`relative rounded-2xl transition-colors hover:bg-gray-800/30 border-solid border border-slate-600 cursor-pointer ${
												activePhone === 2 ? "absolute inset-0 bg-gray-800" : ""
											}`}
											>
												<div className="relative p-8">
													<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
														<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z"
															fill="#A3A3A3"
														></path>
														<path d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z" fill="#737373"></path>
													</svg>

													<h3 className="mt-6 text-lg font-semibold text-white">
														Discount Prioritisation
													</h3>

													<p className="mt-2 text-sm text-gray-400">
														Our Marketplace Algorithm ensures popular, higher discount locations
														receive more traffic... so you get more out of your promotions.
													</p>
												</div>
											</div>

											{/* Third Block */}
											<div
											onClick={() => setActivePhone(3)}
											className={`relative rounded-2xl transition-colors hover:bg-gray-800/30 border-solid border border-slate-600 cursor-pointer ${
												activePhone === 3 ? "absolute inset-0 bg-gray-800" : ""
											}`}
											>
												<div className="relative p-8">
													<svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
														<defs>
															<linearGradient id="gradient3" x1="14" y1="14.5" x2="7" y2="17" gradientUnits="userSpaceOnUse">
																<stop stopColor="#737373"></stop>
																<stop offset="1" stopColor="#D4D4D4" stopOpacity="0"></stop>
															</linearGradient>
														</defs>
														<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z"
															fill="#A3A3A3"
														></path>
														<path d="M7 22c0-4.694 3.5-8 8-8" stroke="url(#gradient3)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
														<path
															d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z"
															fill="#A3A3A3"
														></path>
													</svg>

													<h3 className="mt-6 text-lg font-semibold text-white">
														One-Click Interaction
													</h3>

													<p className="mt-2 text-sm text-gray-400">
														Instant code generation on the user end, and we do all the set-up for you
														on the merchant-side. What's not to like?
													</p>
												</div>
											</div>

									</div>
									<div className="relative col-span-6">

											<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
													<svg viewBox="0 0 558 558" width="558" height="558" fill="none" aria-hidden="true" className="animate-spin-slower">
															<defs>
																	<linearGradient id=":R1ll7qfja:" x1="79" y1="16" x2="105" y2="237" gradientUnits="userSpaceOnUse">
																			<stop stopColor="#13B5C8"></stop>
																			<stop offset="1" stopColor="#13B5C8" stopOpacity="0"></stop>
																	</linearGradient>
															</defs>
															<path opacity=".2" d="M1 279C1 125.465 125.465 1 279 1s278 124.465 278 278-124.465 278-278 278S1 432.535 1 279Z" stroke="#13B5C8"></path>
															<path d="M1 279C1 125.465 125.465 1 279 1" stroke="url(#:R1ll7qfja:)" strokeLinecap="round"></path>
													</svg>
											</div>

											<div className="relative aspect-[366/729] mx-auto w-full max-w-[366px]">
													<div className="absolute inset-y-[calc(1/729*100%)] left-[calc(7/729*100%)] right-[calc(5/729*100%)] rounded-[calc(58/366*100%)/calc(58/729*100%)] shadow-2xl"></div>
													<div className="absolute left-[calc(23/366*100%)] top-[calc(23/729*100%)] grid h-[calc(686/729*100%)] w-[calc(318/366*100%)] transform grid-cols-1 overflow-hidden bg-gray-900">
															<img src={Phone2} className={`${activePhone === 1 ? "block" : "hidden"}`}/>
															<img src={Phone1} className={`${activePhone === 2 ? "block" : "hidden"}`}/>
															<img src={Phone3} className={`${activePhone === 3 ? "block" : "hidden"}`}/>
													</div>
													<svg viewBox="0 0 366 729" aria-hidden="true" className="pointer-events-none absolute inset-0 h-full w-full fill-gray-100">
															<path fill="#F2F2F2" fillRule="evenodd" clipRule="evenodd" d="M300.092 1c41.22 0 63.223 21.99 63.223 63.213V184.94c-.173.184-.329.476-.458.851.188-.282.404-.547.647-.791.844-.073 2.496.257 2.496 2.157V268.719c-.406 2.023-2.605 2.023-2.605 2.023a7.119 7.119 0 0 1-.08-.102v394.462c0 41.213-22.001 63.212-63.223 63.212h-95.074c-.881-.468-2.474-.795-4.323-.838l-33.704-.005-.049.001h-.231l-.141-.001c-2.028 0-3.798.339-4.745.843H66.751c-41.223 0-63.223-21.995-63.223-63.208V287.739c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 284.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-16.289c-.402-.024-2.165-.23-2.524-2.02v-.973A2.039 2.039 0 0 1 1 214.62v-47.611c0-.042.001-.084.004-.126v-.726c0-1.9 1.652-2.23 2.496-2.157l.028.028v-26.041a2.26 2.26 0 0 0 .093-.236l-.064-.01a3.337 3.337 0 0 1-.72-.12l-.166-.028A2 2 0 0 1 1 135.62v-24.611a2 2 0 0 1 1.671-1.973l.857-.143v-44.68C3.528 22.99 25.53 1 66.75 1h233.341ZM3.952 234.516a5.481 5.481 0 0 0-.229-.278c.082.071.159.163.228.278Zm89.99-206.304A4.213 4.213 0 0 0 89.727 24H56.864C38.714 24 24 38.708 24 56.852v618.296C24 693.292 38.714 708 56.864 708h250.272c18.15 0 32.864-14.708 32.864-32.852V56.852C340 38.708 325.286 24 307.136 24h-32.864a4.212 4.212 0 0 0-4.213 4.212v2.527c0 10.235-8.3 18.532-18.539 18.532H112.48c-10.239 0-18.539-8.297-18.539-18.532v-2.527Z"></path>
															<rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4"></rect>
													</svg>
													<svg width="366" height="729" fill="none" xmlns="http://www.w3.org/2000/svg">
															<g mask="url(#mask)">
																<g filter="url(#a)">
																	<path
																		d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
																		fill="url(#b)" />
																	<path
																		d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
																		fill="url(#c)" />
																</g>
																<g filter="url(#d)">
																	<path
																		d="M5 133.772v-21.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v24.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 133.772Z"
																		fill="url(#e)" />
																	<path
																		d="M5 133.772v-21.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v24.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 133.772Z"
																		fill="url(#f)" fillOpacity=".1" />
																</g>
																<g filter="url(#g)">
																	<path
																		d="M5 213.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 213.772Z"
																		fill="url(#h)" />
																	<path
																		d="M5 213.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 213.772Z"
																		fill="url(#i)" fillOpacity=".1" />
																</g>
																<g filter="url(#j)">
																	<path
																		d="M5 283.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 283.772Z"
																		fill="url(#k)" />
																	<path
																		d="M5 283.772v-46.15c0-1.359-.54-2.661-1.5-3.622-.844-.073-2.496.257-2.496 2.157v49.562c.406 2.023 2.605 2.023 2.605 2.023A6.363 6.363 0 0 0 5 283.772Z"
																		fill="url(#l)" fillOpacity=".1" />
																</g>
																<g filter="url(#m)">
																	<path
																		d="M362.004 266.772v-78.15a5.12 5.12 0 0 1 1.5-3.622c.844-.073 2.496.257 2.496 2.157v81.562c-.406 2.023-2.605 2.023-2.605 2.023a6.359 6.359 0 0 1-1.391-3.97Z"
																		fill="url(#n)" />
																	<path
																		d="M362.004 266.772v-78.15a5.12 5.12 0 0 1 1.5-3.622c.844-.073 2.496.257 2.496 2.157v81.562c-.406 2.023-2.605 2.023-2.605 2.023a6.359 6.359 0 0 1-1.391-3.97Z"
																		fill="url(#o)" fillOpacity=".1" />
																</g>
																<path
																	d="M305 14.5H59c-24.577 0-44.5 19.923-44.5 44.5v615c0 23.472 19.028 42.5 42.5 42.5h250c23.472 0 42.5-19.028 42.5-42.5V59c0-24.577-19.923-44.5-44.5-44.5Z"
																	stroke="url(#p)" strokeOpacity=".5" />
																<g filter="url(#q)" shapeRendering="crispEdges">
																	<path
																		d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
																		fill="url(#r)" fillOpacity=".3" />
																	<path
																		d="M305 15.5H59c-24.024 0-43.5 19.476-43.5 43.5v615c0 23.472 19.028 42.5 42.5 42.5h248c23.472 0 42.5-19.028 42.5-42.5V59c0-24.024-19.476-43.5-43.5-43.5Z"
																		stroke="#000" strokeOpacity=".07" />
																</g>
																<g filter="url(#s)">
																	<rect x="154" y="29" width="56" height="5" rx="2.5" fill="#D4D4D4" />
																</g>
															</g>
															<defs>
																<mask id="mask">
																	<rect width="366" height="729" fill="#fff" />
																	<path fillRule="evenodd" clipRule="evenodd"
																		d="M89.728 24a4.213 4.213 0 0 1 4.213 4.212v2.527c0 10.235 8.3 18.532 18.539 18.532h139.04c10.239 0 18.539-8.297 18.539-18.532v-2.527A4.212 4.212 0 0 1 274.272 24h32.864C325.286 24 340 38.71 340 56.853v618.295c0 18.144-14.714 32.853-32.864 32.853H56.864c-18.15 0-32.864-14.709-32.864-32.853V56.853C24 38.709 38.714 24 56.864 24h32.864Z"
																		fill="#000" />
																</mask>
																<linearGradient id="e" x1="1.004" y1="123.367" x2="5" y2="123.367" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</linearGradient>
																<linearGradient id="f" x1="3.002" y1="108.991" x2="3.002" y2="116.75" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="h" x1="1.004" y1="190.867" x2="5" y2="190.867" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</linearGradient>
																<linearGradient id="i" x1="3.002" y1="163.991" x2="3.002" y2="178.497" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="k" x1="1.004" y1="260.867" x2="5" y2="260.867" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</linearGradient>
																<linearGradient id="l" x1="3.002" y1="233.991" x2="3.002" y2="248.497" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="n" x1="362.004" y1="226.25" x2="366" y2="226.25" gradientUnits="userSpaceOnUse">
																	<stop offset=".124" stopColor="#E6E6E6" />
																	<stop offset="1" stopColor="#D4D4D4" />
																</linearGradient>
																<linearGradient id="o" x1="364.002" y1="184.991" x2="364.002" y2="208.134" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#171717" />
																	<stop offset=".783" stopColor="#171717" stopOpacity="0" />
																</linearGradient>
																<linearGradient id="p" x1="182" y1="15" x2="182" y2="716" gradientUnits="userSpaceOnUse">
																	<stop stopColor="#fff" />
																	<stop offset=".381" stopColor="#fff" stopOpacity="0" />
																</linearGradient>
																<filter id="a" x="-1" y="-1" width="367" height="730.314" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="-2" />
																	<feGaussianBlur stdDeviation="1.5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
																	<feBlend in2="shape" result="effect1_innerShadow_104_2007" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-2" />
																	<feGaussianBlur stdDeviation="2" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.17 0" />
																	<feBlend in2="effect1_innerShadow_104_2007" result="effect2_innerShadow_104_2007" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="2" />
																	<feGaussianBlur stdDeviation=".5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0" />
																	<feBlend in2="effect2_innerShadow_104_2007" result="effect3_innerShadow_104_2007" />
																</filter>
																<filter id="d" x="1.004" y="108.991" width="4.996" height="28.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="g" x="1.004" y="163.991" width="4.996" height="53.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="j" x="1.004" y="233.991" width="4.996" height="53.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="m" x="361.004" y="184.991" width="4.996" height="85.751" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="-1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dx="1" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="q" x="15" y="15" width="334" height="703" filterUnits="userSpaceOnUse"
																	colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feGaussianBlur stdDeviation="2.5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<filter id="s" x="154" y="29" width="56" height="6" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
																	<feFlood floodOpacity="0" result="BackgroundImageFix" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feComposite in2="hardAlpha" operator="out" />
																	<feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0" />
																	<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_104_2007" />
																	<feBlend in="SourceGraphic" in2="effect1_dropShadow_104_2007" result="shape" />
																	<feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
																	<feOffset dy="1" />
																	<feGaussianBlur stdDeviation=".5" />
																	<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
																	<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
																	<feBlend in2="shape" result="effect2_innerShadow_104_2007" />
																</filter>
																<radialGradient id="b" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																	gradientTransform="matrix(0 727 -642 0 184 1)">
																	<stop stopColor="#FAFAFA" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</radialGradient>
																<radialGradient id="c" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																	gradientTransform="matrix(0 319 -295.5 0 183.5 1)">
																	<stop stopColor="#fff" />
																	<stop offset=".533" stopColor="#fff" stopOpacity="0" />
																</radialGradient>
																<radialGradient id="r" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
																	gradientTransform="matrix(0 689 -326.783 0 182 27)">
																	<stop offset=".319" stopColor="#D4D4D4" />
																	<stop offset="1" stopColor="#E6E6E6" />
																</radialGradient>
															</defs>
														</svg>
											</div>
									</div>
							</div>
				</div>
			</section>

			{/* Features Section */}
			<section aria-label="Nusmark whatsapp features" className="py-20 sm:py-36">
				<div id="features" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
						<div className="mx-auto max-w-2xl sm:text-center">
								<h2 className="text-3xl font-medium tracking-tight text-gray-900">Give your employees their own PA</h2>
								<p className="mt-2 text-lg text-gray-600">Our users keep asking for more… so we add or upgrade features every week.</p>
								<p className="mt-2 text-lg text-gray-600">Subscribe below to stay updated.</p>
						</div>
						<ul role="list" className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
								<li className="rounded-2xl border border-gray-200 p-8">
										<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
												<path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#737373"></path>
												<path d="M12 25l8-8m0 0h-6m6 0v6" stroke="#171717" strokeWidth="2" strokeLinecap="round"></path>
												<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
										</svg>
										<h3 className="mt-6 font-semibold text-gray-900">Auto-Calendar</h3>
										<p className="mt-2 text-gray-700">Accurately detects Tasks, Events, and Reminders (TERs) then schedules them.</p>
								</li>
								<li className="rounded-2xl border border-gray-200 p-8">
										<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
												<path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#737373"></path>
												<path fillRule="evenodd" clipRule="evenodd" d="M9 13a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H10a1 1 0 01-1-1v-2zm0 6a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H10a1 1 0 01-1-1v-2zm1 5a1 1 0 00-1 1v2a1 1 0 001 1h12a1 1 0 001-1v-2a1 1 0 00-1-1H10z" fill="url(#:S2:-gradient)"></path>
												<rect x="9" y="6" width="14" height="4" rx="1" fill="#171717"></rect>
												<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
												<defs>
														<linearGradient id=":S2:-gradient" x1="16" y1="12" x2="16" y2="28" gradientUnits="userSpaceOnUse">
																<stop stopColor="#737373"></stop>
																<stop offset="1" stopColor="#737373" stopOpacity="0"></stop>
														</linearGradient>
												</defs>
										</svg>
										<h3 className="mt-6 font-semibold text-gray-900">Easy Task Management</h3>
										<p className="mt-2 text-gray-700">Swipe, drag, and drop TERs in our mobile app to accept, delete, or reprioritise.</p>
								</li>
								<li className="rounded-2xl border border-gray-200 p-8">
										<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
												<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
												<path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v10h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h5v2H9a4 4 0 01-4-4V4z" fill="#737373"></path>
												<path fillRule="evenodd" clipRule="evenodd" d="M24 32a8 8 0 100-16 8 8 0 000 16zm1-8.414V19h-2v5.414l4 4L28.414 27 25 23.586z" fill="#171717"></path>
										</svg>
										<h3 className="mt-6 font-semibold text-gray-900">Smart Reminders</h3>
										<p className="mt-2 text-gray-700">Get message reminders for TERs that adapt to your schedule, on any platform(s) you like.</p>
								</li>
								<li className="rounded-2xl border border-gray-200 p-8">
										<svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
												<path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#737373"></path>
												<circle cx="11" cy="14" r="2" fill="#171717"></circle>
												<circle cx="11" cy="20" r="2" fill="#171717"></circle>
												<circle cx="11" cy="26" r="2" fill="#171717"></circle>
												<path d="M16 14h6M16 20h6M16 26h6" stroke="#737373" strokeWidth="2" strokeLinecap="square"></path>
												<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
										</svg>
										<h3 className="mt-6 font-semibold text-gray-900">Location-based Suggestions</h3>
										<p className="mt-2 text-gray-700">Ask for local discount partners, or turn on tracking for location-based TERs.</p>
								</li>
								<li className="rounded-2xl border border-gray-200 p-8">
										<svg viewBox="0 0 32 32" aria-hidden="true" className="h-8 w-8">
												<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
												<path fillRule="evenodd" clipRule="evenodd" d="M5 4a4 4 0 014-4h14a4 4 0 014 4v10h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h5v2H9a4 4 0 01-4-4V4z" fill="#737373"></path>
												<path fillRule="evenodd" clipRule="evenodd" d="M18 19.5a3.5 3.5 0 117 0V22a2 2 0 012 2v6a2 2 0 01-2 2h-7a2 2 0 01-2-2v-6a2 2 0 012-2v-2.5zm2 2.5h3v-2.5a1.5 1.5 0 00-3 0V22z" fill="#171717"></path>
										</svg>
										<h3 className="mt-6 font-semibold text-gray-900">Security Assured</h3>
										<p className="mt-2 text-gray-700">Established Meta Tech Provider and Google Verified. SOC2 Compliant Dashboards.</p>
								</li>
								<li className="rounded-2xl border border-gray-200 p-8">
										<svg viewBox="0 0 32 32" fill="none" aria-hidden="true" className="h-8 w-8">
												<path fillRule="evenodd" clipRule="evenodd" d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z" fill="#737373"></path>
												<path fillRule="evenodd" clipRule="evenodd" d="M23 13.838V26a2 2 0 01-2 2H11a2 2 0 01-2-2V15.65l2.57 3.212a1 1 0 001.38.175L15.4 17.2a1 1 0 011.494.353l1.841 3.681c.399.797 1.562.714 1.843-.13L23 13.837z" fill="#171717"></path>
												<path d="M10 12h12" stroke="#737373" strokeWidth="2" strokeLinecap="square"></path>
												<circle cx="16" cy="16" r="16" fill="#A3A3A3" fillOpacity="0.2"></circle>
										</svg>
										<h3 className="mt-6 font-semibold text-gray-900">Much more on the way</h3>
										<p className="mt-2 text-gray-700">Build, measure, learn, and build again.<br />Want something new? Just ask.</p>
								</li>
						</ul>
				</div>
			</section>

			{/* CTA Section */}
			<section id="newsletter">
				<div className="relative w-full h-[500px] overflow-hidden">
						<iframe className="absolute top-0 left-0 w-full h-full border-none" src="https://nusmark.beehiiv.com/subscribe" ></iframe>
				</div>
      </section>

			{/* Team Section */}
			<section>
				<div className="bg-white py-24 md:py-32">
						<div id="team" className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 lg:grid-cols-5">
								<div className="max-w-2xl col-span-2">
										<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About the team</h2>
										<p className="text-gray-600 text-lg leading-8 mt-6">A Marketplace & Benefits Expert, and an Expert in Building with AI.</p>
										<p className="text-gray-600 text-lg leading-8 mt-6">Supported by the best and brightest from Oxford and Southampton, consistently delivering product (and life!) improvements for you and anyone else who wants to make the most of the corporate workday.</p>
								</div>
								<ul role="list" className="-mt-12 lg:col-span-3">

										<li className="flex flex-col gap-10 pt-12 sm:flex-row">
										<a className="cursor-pointer" href="https://uk.linkedin.com/in/dhruvsengupta" target="_blank" rel="noopener">
												<img className="w-52 flex-none rounded-2xl object-cover" src={Dhruv} alt="" />
										</a>
												<div className="max-w-xl flex-auto">
														<h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">Dhruv Sengupta</h3>
														<p className="text-base leading-7 text-gray-600">CEO</p>
														<ul className="mt-4">
																<li>
																		<p className="font-bold text-base leading-7 text-gray-600">Former Deal Strategy & Wellness Leader at Amazon</p>
																</li>
																<li>
																		<p className="text-base leading-7 text-gray-600">Previous founder: GovTech AI Workflow Start-Up</p>
																</li>
																<li>
																		<p className="text-base leading-7 text-gray-600">Ex-VC Associate & 2024 UK Candidate for MP</p>
																</li>
																<li>
																		<p className="text-base leading-7 text-gray-600">BA Economics & Management at Oxford</p>
																</li>
														</ul>
														<ul role="list" className="mt-4 flex gap-x-1">
																<li>
																		<a className="text-gray-400" href="https://uk.linkedin.com/in/dhruvsengupta" target="_blank" rel="noopener">
																				<svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
																						<path d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" fillRule="evenodd"></path>
																				</svg>
																		</a>
																</li>
																<li>
																		<a className="text-gray-400" href="https://www.ox.ac.uk/" target="_blank" rel="noopener">
																				<img src={Oxford} alt="oxford" className="h-5 w-5" />
																		</a>
																</li>
																<li>
																		<a className="text-gray-400" href="https://www.amazon.co.uk/" target="_blank" rel="noopener">
																				<img src={Amazon} alt="amazon" className="h-5 w-5" />
																		</a>
																</li>
														</ul>
												</div>
										</li>

										<li className="flex flex-col gap-10 pt-12 sm:flex-row">
										<a className="cursor-pointer" href="https://uk.linkedin.com/in/nowacki-jakub" target="_blank" rel="noopener">
												<img className="w-52 flex-none rounded-2xl object-cover" src={Jakub} alt="" />
										</a>
												<div className="max-w-xl flex-auto">
												
														<h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">Jakub Nowacki</h3>
														<p className="text-base leading-7 text-gray-600">CTO</p>
														<ul className="mt-4">
																<li>
																		<p className="font-bold text-base leading-7 text-gray-600">London AI Summit Hackathon Winner '23 & '24</p>
																</li>
																<li>
																		<p className="text-base leading-7 text-gray-600">Data Scientist & AI Project Lead at GE Aviation</p>
																</li>
																<li>
																		<p className="text-base leading-7 text-gray-600">Head of Projects at Southampton AI Society</p>
																</li>
																<li>
																		<p className="text-base leading-7 text-gray-600">3rd Year MEng Comp Sci with AI at Southampton</p>
																</li>
														</ul>
														<ul role="list" className="mt-4 flex gap-x-1">
																<li>
																		<a className="text-gray-400" href="https://uk.linkedin.com/in/nowacki-jakub" target="_blank" rel="noopener">
																				<svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
																						<path d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clipRule="evenodd" fillRule="evenodd"></path>
																				</svg>
																		</a>
																</li>
																<li>
																		<a className="text-gray-400" href="https://www.southampton.ac.uk/" target="_blank" rel="noopener">
																				<img src={Soton} alt="soton" className="h-5 w-5" />
																		</a>
																</li>
																<li>
																		<a className="text-gray-400" href="https://www.geaerospace.com/" target="_blank" rel="noopener">
																				<img src={GE} alt="ge aviation" className="h-5 w-5" />
																		</a>
																</li>
														</ul>
												</div>
										</li>
								</ul>
						</div>
				</div>
			</section>
		</main>
	);
}