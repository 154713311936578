import React from "react";
import NusmarkIcon from "../media/icon.png";
import WhatsAppIcon from "../media/whatsapp.png";

export default function Footer() {
  return (
    <footer className="border-t border-gray-200 bg-white">
      <div className="mx-auto max-w-5xl px-4">
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-y-2.5 w-full lg:w-auto">
            
            {/* Nusmark Icon and Details */}
            <a 
              href="https://nusmark.com/" 
              className="flex items-center text-gray-900 transition-colors hover:bg-gray-100 sm:rounded-2xl lg:p-6"
            >
              <img className="h-14 w-14 flex-none" src={NusmarkIcon} alt="Nusmark Icon" />
              <div className="ml-4 mt-0">
                <p className="text-base font-semibold text-gray-900">Nusmark</p>
                <p className="text-sm text-gray-700">The AI-Powered PA for your life, not just work</p>
              </div>
            </a>

            {/* WhatsApp Button */}
            <a 
              href="https://wa.me/442089418866?text=Press%20the%20send%20button%20(to%20the%20right)%20to%20get%20started%20--%3E"
              className="flex items-center text-gray-900 transition-colors hover:bg-gray-100 sm:rounded-2xl lg:p-6 sm:ml-4"
            >
              <img alt="WhatsApp Icon" loading="lazy" className="h-14 w-14 flex-none" decoding="async" data-nimg="1" src={WhatsAppIcon} />
              <div className="ml-4 mt-0">
                <p className="text-base font-semibold text-gray-900">WhatsApp</p>
                <p className="text-sm text-gray-700">Try the AI PA today on WhatsApp!</p>
              </div>
            </a>
          </div>

          {/* Navigation Links */}
          <nav className="mt-11 flex gap-8">
            <a className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0" href="mailto:hello@nusmark.com">
              <span className="relative z-10">hello@nusmark.com</span>
            </a>
            <a className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0" href="https://nusmark.com/privacy-policy" target="_blank" rel="noreferrer">
              <span className="relative z-10">Privacy Policy</span>
            </a>
          </nav>
        </div>

        <div className="flex flex-col sm:flex-row items-center border-t border-gray-200 mb-8 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          <iframe src="https://embeds.beehiiv.com/5cf368c6-d41d-4259-9fb8-cdd60feddabe?slim=true" data-test-id="beehiiv-embed" height="60" className="m-0 rounded-none bg-transparent"></iframe>
          <p className="mt-6 text-sm text-gray-500 md:mt-0 max-w-2xl">© 2024 Nusmark Ltd. All rights reserved. Nusmark Ltd. is a company registered in England and Wales (Company No. 15859241). 15 Jonquil Gardens, Greater London, England, TW12 3RZ.</p>
        </div>
        <p className="text-sm text-slate-400 leading-none mb-5">Ver {process.env.REACT_APP_VERSION}</p>
      </div>
    </footer>
  );
}
