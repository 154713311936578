import React, { useState, useEffect } from "react";
import axios from "axios";

export default function LinkCalendar() {
  const [statusMessage, setStatusMessage] = useState('');
  const [statusSubtext, setStatusSubtext] = useState('');

  useEffect(() => {
    document.title = "Linking Calendar - Nusmark";

    try {
      const params = new URLSearchParams(window.location.search);
      
      if (!params.has('code')) {
        console.log('No code parameter in the URL, skipping fetch request.');
        setStatusMessage(<strong>Error: Authorization code missing from the URL. This page should not be visited manually.</strong>);
        setStatusSubtext('You can close this page');
        return;
      }

      const baseUrl = 'https://o-auth-calendar-service-1-sguhogpuha-uc.a.run.app/oauth2callback';
      const urlWithParams = `${baseUrl}?${params.toString()}`;

      axios.get(
       urlWithParams
      )
      .then((response) => {
        if (response.data === "Authorization successful!") {
          setStatusMessage(<strong>Calendar Link successful!</strong>);
          setStatusSubtext('Returning you to WhatsApp. If not returned in 3 seconds, please close this window and return to the Nusmark chat.');
  
          setTimeout(() => {
            const whatsappUrl = "https://wa.me/442089418866";
            window.location.href = whatsappUrl;
  
          }, 2500);

        } else {
          setStatusMessage(<strong>Calendar Link failed</strong>);
          setStatusSubtext('Returning you to WhatsApp in 3 seconds to try again. If not returned in 3 seconds, please close this window and return to the Nusmark chat.');
  
          setTimeout(() => {
            const whatsappUrl = "https://wa.me/442089418866";
            window.location.href = whatsappUrl;
  
          }, 2500);

        }
      })
      .catch((error) => {
        setStatusMessage(<strong>Calendar Link failed</strong>);
        setStatusSubtext('Returning you to WhatsApp in 3 seconds to try again. If not returned in 3 seconds, please close this window and return to the Nusmark chat.');

        setTimeout(() => {
          const whatsappUrl = "https://wa.me/442089418866";
          window.location.href = whatsappUrl;

        }, 2500);
      });     

    } catch (error) {
      setStatusMessage(<strong>Calendar Link failed</strong>);
      setStatusSubtext('Returning you to WhatsApp in 3 seconds to try again. If not returned in 3 seconds, please close this window and return to the Nusmark chat.');

      setTimeout(() => {
        const whatsappUrl = "https://wa.me/442089418866";
        window.location.href = whatsappUrl;

      }, 2500);
    }

  }, []);

  return (
    <div>
      <div>{statusMessage}</div>
      <div>{statusSubtext}</div>
    </div>
  );
}