import { React, useEffect} from "react";

export default function PrivacyPolicy() {

	useEffect(() => {
		document.title = 'Privacy Policy - Nusmark';
	  }, []);

	return (
		<main className="bg-white text-black font-biryani">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative py-10">
				<h1 className="text-4xl text-black">Privacy Policy</h1>
				<p className="text-base py-3 font-bold mt-5">Version 2.2 | Last Updated: 7th September 2024</p>

				<h2 className="text-black text-3xl mt-5">Introduction</h2>
				<p className="text-base py-3">
						Nusmark (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal data when you use our services through our application (“App”), website, and related platforms (collectively referred to as “Services”).
				</p>
				<p className="text-base py-3">
						By using our Services, you agree to the collection and use of your information in accordance with this Privacy Policy and applicable data protection laws, including the Data Protection Act 2018 (DPA 2018) and General Data Protection Regulation (GDPR).
				</p>

				<h2 className="text-black text-3xl mt-6">1. Information We Collect</h2>
				<h3 className="text-black text-2xl mt-5">1.1 Personal Data</h3>
				<p className="text-base py-3">When you sign up for or use our Services, we may collect personal data such as your:</p>
				<ul className="text-base ml-5">
						<li>Name</li>
						<li>Email address</li>
						<li>Phone number</li>
						<li>Other contact details</li>
						<li>User ID or Account Information</li>
				</ul>

				<h3 className="text-black text-2xl mt-5">1.2 Usage Data</h3>
				<p className="text-base py-3">We may collect data on how you interact with our Services, including:</p>
				<ul className="text-base cul">
						<li>Messages and emails you allow access to or forward through the App (for the purpose of identifying tasks, events, and reminders)</li>
						<li>Calendar events and notifications you create through our App</li>
						<li>Log files, IP addresses, device types, and usage patterns</li>
						<li>Data on interactions with our Services, such as features accessed and usage time</li>
				</ul>

				<h3 className="text-black text-2xl mt-5">1.3 Data from Integrations</h3>
				<p className="text-base py-3">If you choose to integrate your Google or Outlook calendar with Nusmark, we collect data necessary to provide functionalities including Auto Calendar and Smart Reminder. This may include calendar events, scheduled appointments, and related metadata.</p>

				<h2 className="text-black text-3xl mt-6">2. Lawful Basis for Processing</h2>
				<p className="text-base py-3">We process your personal data based on one or more of the following legal bases:</p>
				<ul className="text-base cul">
						<li><strong>Consent:</strong> Where you have provided clear consent for us to process your data for a specific purpose.</li>
						<li><strong>Contract:</strong> Where processing is necessary for the performance of a contract with you or to take steps at your request before entering into a contract.</li>
						<li><strong>Legitimate Interests:</strong> Where processing is necessary for our legitimate interests, and those interests are not overridden by your data protection rights.</li>
				</ul>

				<h2 className="text-black text-3xl mt-6">3. How We Use Your Information</h2>
				<p className="text-base py-3">We use the information collected to:</p>
				<ul className="text-base cul">
						<li>Identify tasks, events, and reminders in your messages.</li>
						<li>Send you smart notifications and sync your calendars.</li>
						<li>Improve our AI algorithms using anonymized data.</li>
						<li>Ensure the secure and effective operation of our services.</li>
						<li>Send you updates on new features, promotions, and important changes related to Nusmark.</li>
				</ul>

				<h2 className="text-black text-3xl mt-6">4. Sharing, Transfer, and Disclosure of Google User Data</h2>
				<p className="text-base py-3">Nusmark will only share, transfer, or disclose Google user data in accordance with the following:</p>
				<ul className="text-base cul">
						<li><strong>Third-Party Service Providers:</strong> We may share your data with trusted third-party service providers that help us operate our Services. These providers are bound by contractual obligations to protect the confidentiality and security of your data.</li>
						<li><strong>Legal Obligations:</strong> We may disclose your Google user data if required by law, regulation, or legal process.</li>
						<li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of our assets, Google user data may be transferred, subject to the same privacy protections.</li>
						<li><strong>With Your Consent:</strong> We may share your data where you have provided consent for third-party integrations or services requested by you.</li>
				</ul>
				<p className="text-base py-3">We will not sell or rent your Google user data to any third parties for marketing purposes.</p>

				<h2 className="text-black text-3xl mt-6">5. Your Rights Under GDPR or DPA 2018</h2>
				<p className="text-base py-3">You have the following rights concerning your personal data:</p>
				<ul className="text-base cul">
						<li><strong>Right to Access:</strong> You can request access to the data we hold about you.</li>
						<li><strong>Right to Rectification:</strong> You can ask us to correct any inaccurate or incomplete data.</li>
						<li><strong>Right to Erasure:</strong> You can request that we delete your data, subject to legal obligations.</li>
						<li><strong>Right to Restriction of Processing:</strong> You can ask us to limit the processing of your data.</li>
						<li><strong>Right to Data Portability:</strong> You can request that your data be provided in a structured, machine-readable format and ask that we transfer it to another controller.</li>
						<li><strong>Right to Object:</strong> You can object to the processing of your data for certain purposes.</li>
				</ul>
				<p className="text-base py-3">To exercise any of these rights, please contact us at <a href="mailto:legal@nusmark.com">legal@nusmark.com</a>.</p>

				<h2 className="text-black text-3xl mt-6">6. Data Transfers Outside the UK and EEA</h2>
				<p className="text-base py-3">If we transfer your data outside the UK or European Economic Area (EEA), we will ensure that it is protected by appropriate safeguards, such as Standard Contractual Clauses or an adequacy decision by the European Commission.</p>

				<h2 className="text-black text-3xl mt-6">7. Data Retention</h2>
				<p className="text-base py-3">We retain your data only as long as necessary to provide our Services, comply with legal obligations, and resolve disputes. When data is no longer needed, we securely delete or anonymize it.</p>

				<h2 className="text-black text-3xl mt-6">8. Data Security</h2>
				<p className="text-base py-3">We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

				<h2 className="text-black text-3xl mt-6">9. Consent and Withdrawal</h2>
				<p className="text-base py-3">If our processing is based on your consent, you have the right to withdraw that consent at any time by contacting us or through the options provided within our Services.</p>

				<h2 className="text-black text-3xl mt-6">10. Supervisory Authority and Complaints</h2>
				<p className="text-base py-3">If you believe that your rights under GDPR have been violated, you have the right to lodge a complaint with your local data protection authority.</p>

				<h2 className="text-black text-3xl mt-6">11. Changes to This Privacy Policy and T&Cs</h2>
				<p className="text-base py-3">We may update this Privacy Policy and/or our Terms & Conditions from time to time. When we do, we will revise the “Last Updated” date at the top of the respective policy/policies. We encourage you to review these policies periodically to stay informed about how we protect your information and your obligations by way of usage.</p>

				<h2 className="text-black text-3xl mt-6">12. Contact Us</h2>
				<p className="text-base py-3">If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
				<div className="mt-6">
						<p className="text-base py-3"><strong>Data Protection Officer:</strong> Dhruv Sengupta</p>
						<p className="text-base py-3"><strong>Email:</strong> <a href="mailto:legal@nusmark.com">legal@nusmark.com</a></p>
						<p className="text-base py-3"><strong>Address:</strong> 15 Jonquil Gardens, Greater London, England, TW12 </p>
				</div> 
      </div>
		</main>
	);
}