import React, { useState } from "react";
import WhatsAppButton from "../media/whatsapp_button.png";
import LightLogo from '../media/Hybrid-Cropped-Light-Mode.jpg';

export default function MobileNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white font-biryani sticky top-0 z-10">
      <nav>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative flex justify-between py-6 z-10">
          <div className="relative flex items-center gap-12">
            <a href="https://nusmark.com">
              <img src={LightLogo} alt="logo" className="h-8 w-auto" />
            </a>
          </div>

          <button
          className="relative m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900"
          aria-label="Toggle site navigation"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          >
            <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className="h-6 w-6">
              <path
                d="M5 6h14M5 18h14M5 12h14"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </div>

        <div className={`absolute inset-x-0 top-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20 ${isOpen ? "" : "hidden"}`}>        
          <div className="space-y-4">
            <a
              className="block text-base leading-7 tracking-tight text-gray-700"
              href="https://nusmark.com/#corporate_benefits"
              onClick={() => setIsOpen(false)}
            >
              Corporate Benefits
            </a>
            <a
              className="block text-base leading-7 tracking-tight text-gray-700"
              href="https://nusmark.com/#discount_provider"
              onClick={() => setIsOpen(false)}
            >
              Discount Partners
            </a>
            <a
              className="block text-base leading-7 tracking-tight text-gray-700"
              href="https://nusmark.com/#features"
              onClick={() => setIsOpen(false)}
            >
              Features
            </a>
          </div>
          <div className="mt-8 flex flex-col gap-4">
            <a
              className="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80"
              href="https://nusmark.com/#team"
              onClick={() => setIsOpen(false)}
            >
              About us
            </a>
            <a
              className="inline-flex justify-center items-center rounded-lg transition-transform duration-300 transform bg-gray-800 text-white hover:bg-gray-900 hover:scale-105"
              href="https://wa.me/442089418866?text=Press%20the%20send%20button%20(to%20the%20right)%20to%20get%20started%20--%3E"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={WhatsAppButton} alt="whatsapp button" width="120" height="40" />
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}
