import React from 'react';
import LightLogo from '../media/Hybrid-Cropped-Light-Mode.jpg';
import WhatsAppButton from '../media/whatsapp_button.png';

export default function Navbar() {

	return (
		<header className="bg-white font-biryani sticky top-0 z-10">
      <nav>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative flex justify-between py-6">
          <div className="relative flex items-center gap-12">

            <a href="https://nusmark.com/">
              <img src={LightLogo} alt="logo" className="h-8 w-auto" />
            </a>

            <div className="hidden lg:flex lg:gap-10 text-center mt-3">
              <a
                className="relative -mx-3 mt-2 rounded-lg px-3 py-2 text-sm text-gray-500 transition-colors delay-150 hover:text-gray-900 hover:delay-0 hover:bg-gray-100"
                href="https://nusmark.com/#corporate_benefits"
              >
                <span className="relative">Corporate Benefits</span>
              </a>

              <a
                className="relative -mx-3 mt-2 rounded-lg px-3 py-2 text-sm text-gray-500 transition-colors delay-150 hover:text-gray-900 hover:delay-0 hover:bg-gray-100"
                href="https://nusmark.com/#discount_provider"
              >
                <span className="relative">Discount Partners</span>
              </a>

              <a
                className="relative -mx-3 mt-2 rounded-lg px-3 py-2 text-sm text-gray-500 transition-colors delay-150 hover:text-gray-900 hover:delay-0 hover:bg-gray-100"
                href="https://nusmark.com/#features"
              >
                <span className="relative">Features</span>
              </a>
            </div>
          </div>

          <div className="flex items-center gap-6">
            <a
              className="lg:block inline-flex justify-center rounded-lg border pb-[calc(theme(spacing.2)-2px)] pt-[calc(theme(spacing.2)+1px)] px-[calc(theme(spacing.3)-1px)] text-sm font-semibold outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 bg-white hover:border-gray-400 hover:bg-gray-100"
              href="https://nusmark.com/#team"
            >
              About us
            </a>
            <a
              className="hidden lg:block whatsapp-button zoom-on-hover rounded-lg transition-colors bg-gray-800 text-white hover:bg-gray-900"
              href="https://wa.me/442089418866?text=Press%20the%20send%20button%20(to%20the%20right)%20to%20get%20started%20--%3E"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={WhatsAppButton}
                alt="whatsapp button"
                width="120"
                height="40"
                className="transition-transform duration-300 ease-in-out hover:scale-105"
              />
            </a>
          </div>
        </div>
			</nav>
		</header>
	);
}
